.enlarge-part svg .enlarge {
  transform: scale(1.2);
}

.enlarge-part svg .enlarge.small-hover {
  transform: scale(1.05);
}

.outline {
  outline-width: 4px;
  outline-color: #fff;
  outline-style: dashed;
  outline-offset: 7px;
}

main[data-tutorial-index='7'] .outline {
  outline-color: #000;
}

svg > g {
  cursor: pointer;
  transition: transform 0.1s;
  transform-origin: center;
  transform-box: fill-box;
}

svg > g.disabled {
  cursor: not-allowed;
}

svg > g:hover {
  transition: transform 0.3s;
  transform: scale(1.2);
}

svg > g.small-hover:hover {
  transform: scale(1.02);
}

.animated {
  overflow: visible;
}

.active-editing .animated {
  animation-fill-mode: none;
}

.enlarge-part svg .enlarge {
  transform: scale(1.2);
}

.enlarge-part svg .enlarge.small-hover {
  transform: scale(1.05);
}
